import axios from "axios";
import * as Config from "../utils/Config";

async function UserExist(token) {
  try {
    const response = await axios.get(`${Config.APIUrl}/users/isExist`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': `${Config.MAINUrl}`
      }
    })
    return response.data.exist
  } catch {
    console.error('There was a problem getting your user information')
    return undefined
  }
}

export default UserExist