import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Stack } from '@mui/material';
import { supabase } from '../../services/supabaseClient';
// import { MAINUrl } from '../../utils/requestManager'
import * as Config from '../../utils/Config'

const Settings = () => {

  const navigate = useNavigate()

  async function signOut() {
    const { error } = await supabase.auth.signOut()
    if (error) {
      console.log(`Settings -> signOut -> Error to sign out : ${error}`)
    } else {
      localStorage.removeItem("geniusHelper")
      const goToUrl = new URL(Config.MAINUrl)
      window.parent.location.href = goToUrl.href.toString()
      navigate("/");
    }
  }

  useEffect(() => {
    console.log(`Settings -> useEffect ->`)
  }, [])

  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <Stack direction='column' spacing={2}>
          <Button color="error" onClick={() => { signOut() }} >Sign out</Button>
        </Stack>
      </Box>
    </>
  )
}

export default Settings