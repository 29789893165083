import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import * as Congig from '../utils/Config'

const socket = io(`${Congig.APIChatUrl}`);

function ChatRoom({ roomId }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [typingUser, setTypingUser] = useState(null);
  const [sender] = useState(`User${Math.floor(Math.random() * 1000)}`);

  useEffect(() => {
    socket.emit("joinRoom", { roomId, sender });

    socket.on("receiveMessage", (message) => {
      setMessages((prev) => [...prev, message]);
      setTypingUser(null);
    });

    socket.on("userTyping", ({ sender }) => {
      setTypingUser(sender);
    });

    return () => socket.disconnect();
  }, [roomId, sender]);

  const sendMessage = () => {
    if (input.trim() !== "") {
      socket.emit("sendMessage", { roomId, sender, content: input });
      setInput("");
    }
  };

  const handleTyping = () => {
    socket.emit("typing", { roomId, sender });
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Chat Room {roomId}</h1>
      <div style={{ border: "1px solid #ccc", padding: "10px", height: "400px", overflowY: "scroll" }}>
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              textAlign: msg.sender === sender ? "right" : "left",
              margin: "10px 0",
            }}
          >
            <div style={{
              display: "inline-block",
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: msg.sender === sender ? "#DCF8C6" : "#FFF",
            }}>
              <strong>{msg.sender}</strong>: {msg.content}
            </div>
          </div>
        ))}
        {typingUser && <div><em>{typingUser} is writting...</em></div>}
      </div>
      <input
        style={{ width: "80%", padding: "10px" }}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleTyping}
        placeholder="Écrivez un message..."
      />
      <button style={{ width: "20%" }} onClick={sendMessage}>Envoyer</button>
    </div>
  );
}

export default ChatRoom;