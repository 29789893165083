import React from 'react';
import { useNavigate, redirect } from 'react-router-dom';
import {
    AppBar, Toolbar, Typography, Button, Box, Avatar,
    Menu, MenuItem, ListItemIcon, Divider, IconButton, Tooltip
} from '@mui/material';

import { PersonAdd, Settings, Logout, Assignment, Dashboard, Construction } from '@mui/icons-material';
import { red, blue, grey, orange } from '@mui/material/colors'
import { useAuth } from '../context/AuthContext';


function AccountMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const { login, logout } = useAuth()
    const navigate = useNavigate()

    const handleClick = (event) => {

        setAnchorEl(event.currentTarget);
    };
    const handleClose = (whichOne) => {
        console.log('AccountMenu -> handleClose : ', { anchorEl });
        setAnchorEl(null);
        // logout()
        // navigate('/')
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
                <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
                <Tooltip title="Account settings">
                    {/* <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                    </IconButton> */}
                </Tooltip>
            </Box>
            {/* <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                <MenuItem onClick={handleClose}>
                    <Assignment /> Nothing
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { navigate('/dashboard') }}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Dashboard
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={() => { navigate('/login') }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu> */}
        </React.Fragment>
    );
}

const Header = ({ title = 'Mon Application', onLogin, onRegister }) => {

    const navigate = useNavigate()

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    {title}
                </Typography>
                <AccountMenu />
                {onLogin && (
                    <Button color="inherit" onClick={() => { navigate('/login') }}>
                        Connexion
                    </Button>
                )}
                {onRegister && (
                    <Button color="inherit" onClick={onRegister}>
                        Inscription
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;