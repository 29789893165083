
import logo1 from '../Assets/logo-1.png'
import logo1w from '../Assets/logo-1w.png'
import logo2 from '../Assets/logo-2.png'
import icon from '../Assets/icon.png'
import iconw from '../Assets/icon-w.png'
import favIcon from '../Assets/favIcon.png'

export const APIUrl = 'https://api.magraf.fr:8443'
export const APIChatUrl = 'https://api.magraf.fr:8443'
// export const APIUrl = 'https://api.3dfitter.com:8444'
export const MAINUrl = 'https://studio.magraf.fr'

export const AppName = 'Chatly'
export const AppLogo = logo1
export const AppLogoWhiteBG = logo1w
export const AppLogoWOText = logo2
export const AppIcon = icon
export const AppIconWhiteBG = iconw
export const AppFavIcon = favIcon
