import React from 'react';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import UserSession from '../services/userSession';
import { Backdrop, CircularProgress } from '@mui/material';
import { red, blue, grey, orange } from '@mui/material/colors'

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);
    const [isLaoding, setIsLoading] = useState(true);

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        const token = localStorage.getItem('geniusHelper')
        if (token) {
            const permission = async (token) => {
                try {
                    const permission = await UserSession(token)
                    if (permission === null || permission === undefined || permission === false) {
                        setIsAuthenticated(false);
                        setHasPermission(false);
                        setIsLoading(false);
                        return undefined;
                    }
                    if (permission === true) {
                        console.log('You have permission to access this page');
                        setIsAuthenticated(true);
                        setHasPermission(true);
                    }
                } catch (error) {
                    console.error('There was a problem getting your user information');
                    setIsAuthenticated(false);
                    setHasPermission(false);
                    return undefined;
                }
            }
            permission(token).finally(() => { setIsLoading(false) })
        } else {
            setLoading(false)
            setIsLoading(false)
        }
    }, [])
    if (isLaoding) {
        return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} >
            <CircularProgress size={50} sx={{ color: grey[50] }} />
        </Backdrop>;
    }
    if (!isAuthenticated || !hasPermission) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;