import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, TextField, Button, Typography, Grid2, Alert, Avatar, Stack, IconButton, FormControl,
  Backdrop, CircularProgress, Divider, InputLabel, OutlinedInput, InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { red, blue, grey, orange } from '@mui/material/colors'

import { supabase } from '../services/supabaseClient';
import UserExist from '../Users/UserExist';
import UserPermission from '../Users/UserPermission';
import { InputOtp } from '../components/InputOtp'
import PasswordStrengthMeter from '../components/PasswordStrengthMeter';

import * as Config from '../utils/Config'

const LoginPage = () => {

  const navigate = useNavigate()
  const [session, setSession] = useState(null)
  const [email, setEmail] = useState('');
  const [emailSignUp, setEmailSignUp] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState("");
  const [errorText, setErrorText] = useState("");
  const [errorTextSignUp, setErrorTextSignUp] = useState("")
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const validateMail = (email) => {
    const setupMail = email.toLowerCase()
    return setupMail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  }

  const onClickSign = async () => {
    setLoading(true)
    const { data, error } = await supabase.auth.signInWithOtp({
      email: email,
      options: { shouldCreateUser: false }
    })
    console.log(`Login Page -> onClickSign -> getToken : ${JSON.stringify(data, null, 2)}`)
    if (error) {
      setEmailSent(false)
      setLoading(false)
      setErrorText(error.message)
    } else {
      setEmailSent(true)
      setLoading(false)
      setErrorText('')

    }
  }

  const onClickSignUp = async () => {
    setLoading(true)
    const { data, error } = await supabase.auth.signUp({
      email: emailSignUp,
      password: password,
      options: { shouldCreateUser: false }
    })
    console.log(`Login Page -> onClickSign -> getToken : ${JSON.stringify(data, null, 2)}`)
    if (error) {
      // setEmailSent(false)
      setLoading(false)
      setErrorTextSignUp(error.message)
    } else {
      // setEmailSent(true)
      setLoading(false)
      setErrorTextSignUp('')

    }
  }

  const handleOTPValueChange = async (newValues) => {
    console.log(`Login Page -> handleOTPValueChange -> newValue : ${newValues}`)
    console.log(`Login Page -> handleOTPValueChange -> email : ${email}`)
    const str = newValues.join("")
    console.log(`Login Page -> handleOTPValueChange -> str : ${str}`)
    setToken(str)
  }

  const onClickOTP = async () => {

    setLoading(true)
    const { data, error } = await supabase.auth.verifyOtp({
      email, token, type: 'email'
    })
    if (error) {
      setLoading(false)
      setEmailSent(false)
      setErrorText(error.message)
    } else {
      setLoading(false)
      setEmailSent(true)
      setErrorText('')
      try {
        const userExist = await UserExist(data.session.access_token)

        if (userExist === true) {
          const permission = await UserPermission(
            data.session.access_token
          )
          if (permission !== null && permission !== undefined) {
            localStorage.setItem('geniusHelper', permission)
            // navigate('/dashboard')
            navigate('/')
          } else {
            setErrorText("You do not have the permission to login")
          }
        } else if (userExist === undefined) {
          setErrorText("Impossible to fetch user information");
          return;
        } else {
          setErrorText("User don't exist, please check with an administrator")
        }
      } catch (error) {
        setErrorText(`Failed to fetch you user information with ${error}`)
      }
    }
  }

  const displayForm = () => {

    return (
      emailSent === true ?
        <>
          <Stack direction='column' spacing={2} sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Typography variant="body" align="center" gutterBottom>
              <b>{email}</b>
            </Typography>
            <InputOtp onValuesChange={handleOTPValueChange} />
            <Typography variant="body" align="center" gutterBottom>
              Enter your one-time password
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
              onClick={onClickOTP}
              disabled={token === ""}
            >
              Validate One Time Password
            </Button>
          </Stack>

        </>
        :
        <>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={onClickSign}
            disabled={!validateMail(email)}
          >
            Send One Time Password
          </Button>
        </>
    )

  }

  const displaySignUPForm = () => {

    return (
      <>

        <TextField
          label="Email"
          type="email"
          value={emailSignUp}
          onChange={(e) => setEmailSignUp(e.target.value)}
          fullWidth
          required
          margin="normal"
        />

        <FormControl variant="outlined" fullWidth>

          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>

          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            margin="normal"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? 'hide the password' : 'display the password'
                  }
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />

        </FormControl>

        <PasswordStrengthMeter password={password} />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
          onClick={onClickSignUp}
          disabled={!validateMail(emailSignUp)}
        >
          Sign Up
        </Button>

      </>
    )
  }

  useEffect(() => {

    console.log('NavBar -> useEffect -> Current URL : ' + window.location.href);
    console.log('NavBar -> useEffect -> Current URL : ' + window.location.pathname);

    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession()
      setSession(session)
    }

    fetchSession()

    const { data: subscription } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
    // return () => {
    //     subscription.unsubscribe()
    // }
  })

  return (
    <Grid2
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh', backgroundColor: '#f5f5f5' }}
    >

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} >
        <CircularProgress size={50} sx={{ color: grey[50] }} />
      </Backdrop>

      <Stack direction='row' spacing={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>

        <Stack direction='column' spacing={3} sx={{
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Avatar src={Config.AppLogo} sx={{ width: 200, height: 200 }} />
          <Box
            sx={{
              backgroundColor: '#fff',
              padding: 4,
              borderRadius: 2,
              boxShadow: 3,
              maxWidth: 400,
              width: '100%',
            }}
          >
            <Typography variant="h6" align="center" gutterBottom>
              Signin to your account
            </Typography>

            {errorText && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {errorText}
              </Alert>
            )}

            {displayForm()}

          </Box>
        </Stack>

        <Divider orientation="vertical" flexItem />

        <Stack direction='column' spacing={3} sx={{
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Avatar src={Config.AppLogo} sx={{ width: 200, height: 200 }} />
          <Box
            sx={{
              backgroundColor: '#fff',
              padding: 4,
              borderRadius: 2,
              boxShadow: 3,
              maxWidth: 400,
              width: '100%',
            }}
          >
            <Typography variant="h6" align="center" gutterBottom>
              You doesn't an account, create one !
            </Typography>

            {errorTextSignUp && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {errorText}
              </Alert>
            )}

            {displaySignUPForm()}

          </Box>

        </Stack>

      </Stack>

    </Grid2>
  );
};

export default LoginPage;