import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: '#1976d2',
                color: '#fff',
                textAlign: 'center',
                py: 2,
                mt: 'auto',
            }}
        >
            <Typography variant="body2">
                © {new Date().getFullYear()} Tailored fit. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;