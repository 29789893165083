import axios from "axios";
import * as Config from '../utils/Config'

async function UserPermission(token) {
  try {
      const response = await axios.get(`${Config.APIUrl}/analytics/permissions`, {
          headers: {
          'Authorization': `Bearer ${token}`,
          'Access-Control-Allow-Origin': `${Config.MAINUrl}`
          }
      })
      if (response.data === null || response.data === undefined) {
          return undefined
      }
      if (response.data.success === false) {
          return undefined
      }
      return response.data.permission;
      
  } catch {
      console.error('There was a problem getting your user information');
      return undefined
  }
}

export default UserPermission