import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Grid2, Paper } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';


const NotFound = () => {
    const handleLearnMore = () => {
        console.log('Learn more clicked!');
    };

    const handleGetStarted = () => {
        console.log('Get started clicked!');
    };
    const navigate = useNavigate(); // Utilisation de navigate pour rediriger vers la page de login

    const handleLoginClick = () => {
        navigate('/login'); // Redirige vers la page Login
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            {/* Header */}
            <Header title="Dashboard" onLogin={true} />

            {/* Contenu principal */}
            <Box
                sx={{
                    flexGrow: 1,
                    padding: 3,
                    backgroundColor: '#f5f5f5',
                }}
            >
                <Typography variant="h5" gutterBottom>
                    Sorry, thid page doesn't exist
                </Typography>

                
            </Box>

            {/* Footer */}
            <Footer />
        </Box>
    );
};

export default NotFound;