import React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';


import LoginPage from '../pages/LoginPage';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/404';


const AppRoutes = () => {
    
    return (
        <Routes>
            {/* <Route path="/" element={<HomePage />} /> */}
            <Route path="/login" element={<LoginPage />} />
            <Route path='*' element={<NotFound />} />
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            />
            {/* <Route
                path="/dashboard"
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            /> */}
            {/* <Route
                path="/swagger"
                element={
                    <ProtectedRoute>
                        <Swagger />
                    </ProtectedRoute>
                }
            /> */}
        </Routes>
    );
};

export default AppRoutes;