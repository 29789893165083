import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import {
  Box, TextField, Button, Typography, Grid2, Alert, Avatar, Stack,
  Backdrop, CircularProgress
} from '@mui/material';
import { red, blue, grey, orange } from '@mui/material/colors'

import * as Config from '../utils/Config'

function UsersList() {

  const [loading, setLoading] = useState(false);
  
    useEffect(() => {
  
  
    }, []);
  
  
    return (
      <Grid2
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh', backgroundColor: '#f5f5f5' }}
      >
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} >
          <CircularProgress size={50} sx={{ color: grey[50] }} />
        </Backdrop>
        <Stack direction='column' spacing={3} sx={{
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Avatar src={Config.AppLogo} sx={{ width: 100, height: 100 }} />
          <Box
            sx={{
              backgroundColor: '#fff',
              padding: 4,
              borderRadius: 2,
              boxShadow: 3,
              maxWidth: 400,
              width: '100%',
            }}
          >
            <Typography variant="h3" align="center" gutterBottom>
              {Config.AppName}
            </Typography>
  
            <Typography variant="h3" align="center" gutterBottom>
              Users List
            </Typography>
          </Box>
        </Stack>
      </Grid2>
    );

}


export default UsersList;