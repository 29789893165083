import React, { useState, useEffect } from 'react';
import { TextField, Stack, Paper } from '@mui/material';
import { styled } from '@mui/material/styles'

const InputOtp = ({ onValuesChange }) => {

    const [values, setValues] = useState(Array(6).fill(""));
    const [activeIndex, setActiveIndex] = useState(0);

    const handleChange = (e, index) => {
        const value = e.target.value;

        if (/^\d$/.test(value)) {
            const newValues = [...values];
            newValues[index] = value;
            setValues(newValues);
            onValuesChange(newValues); // Transmet les valeurs mises à jour

            if (index < values.length - 1) {
                setActiveIndex(index + 1);
                document.getElementById(`otp${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            const newValues = [...values];

            if (values[index] === "" && index > 0) {
                setActiveIndex(index - 1);
                document.getElementById(`otp${index - 1}`).focus();
            } else {
                newValues[index] = "";
                setValues(newValues);
                onValuesChange(newValues); // Transmet les valeurs mises à jour
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData("text");
        const digits = pasteData.replace(/\D/g, "").slice(0, 6);

        if (digits) {
            const newValues = [...values];
            for (let i = 0; i < digits.length; i++) {
                newValues[i] = digits[i];
            }
            setValues(newValues);
            onValuesChange(newValues); // Transmet les valeurs mises à jour

            setActiveIndex(digits.length - 1);

            if (digits.length < 6) {
                document.getElementById(`otp${digits.length}`).focus();
            }
        }
    };

    return (
        <div>
            <Stack direction="row">
                {values.map((_, index) => (
                    <TextField
                        key={index}
                        id={`otp${index}`}
                        value={values[index]}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={index === 0 ? handlePaste : undefined}
                        disabled={index > activeIndex}
                        inputProps={{
                            maxLength: 1,
                            style: { textAlign: "center" },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius:
                                    index === 0
                                        ? "8px 0 0 8px"
                                        : index === values.length - 1
                                            ? "0 8px 8px 0"
                                            : "0",
                                borderRight: index < values.length - 1 ? "none" : undefined,
                                borderColor: "#ccc",
                                width: 40,
                            },
                        }}
                    />
                ))}
            </Stack>
        </div>
    );

}

export { InputOtp }