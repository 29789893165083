import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Grid2 } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { extendTheme, styled } from '@mui/material/styles';
import {
    DashboardIcon, ShoppingCartIcon, BarChartIcon, DescriptionIcon, Comment, Forum,
    Equalizer, DataUsage, Leaderboard, ManageSearch, AutoGraph, LegendToggle,
    PowerSettingsNew, ManageAccounts, Person, AdminPanelSettings, People, Person2,
    Science
} from '@mui/icons-material';

import { AppProvider, DashboardLayout, PageContainer } from '@toolpad/core';
import Grid from '@mui/material/Grid2';

import * as Config from '../utils/Config'

import Chat from '../components/ChatRoom'
import Message from '../components/Message';
import UsersList from '../components/UsersList';

// Settings
import Settings from '../components/Settings.js/Settings'

const BRANDING = {
    logo: <img src={Config.AppLogo} alt={Config.AppName} />,
    title: Config.AppName,
};

const NAVIGATION = [
    {
        segment: 'chat',
        title: 'Chat',
        icon: <Forum />,
    },
    {
        segment: 'message',
        title: 'Message',
        icon: <Comment />,
    },
    {
        segment: 'users',
        title: 'Users',
        icon: <Person />,
    },
    {
        segment: 'settings',
        title: 'Settings',
        icon: <ManageAccounts />,
    },
];

const demoTheme = extendTheme({
    colorSchemes: { light: true, dark: true },
    colorSchemeSelector: 'class',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

const whichPage = (whichOne) => {
    console.log(`Dashboard -> whichPage -> pathname : ${whichOne}`)
    switch (whichOne) {
        case '/chat':
            return <Chat />
        case '/users':
            return <UsersList />
        case '/settings':
            return <Settings />

        default:
            return <Chat />
    }
}

const Dashboard = (props) => {

    const { window } = props;
    const demoWindow = window ? window() : undefined;
    const [pathname, setPathname] = useState('/');

    const router = useMemo(() => {
        return {
            pathname,
            searchParams: new URLSearchParams(),
            navigate: (path) => setPathname(String(path)),
        };
    }, [pathname]);

    useEffect(() => {

    }, [])

    return (

        <AppProvider
            navigation={NAVIGATION}
            router={router}
            theme={demoTheme}
            window={demoWindow}
            branding={BRANDING}
        >
            <DashboardLayout>
                <PageContainer>
                    {
                        whichPage(pathname)
                    }
                </PageContainer>
            </DashboardLayout>
        </AppProvider>
    );
};

export default Dashboard;